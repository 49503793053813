<template>
  <div class="operation">
    <div class="header">
      <h6>经营分析</h6>
      <div class="btn-wrap">
        <p class="btn"
           @click="exportClick"><i class="icon iconfont icon-daochu btn"></i><span>导出</span></p>
      </div>
    </div>
    <el-card style="margin-top:20px">
      <el-form :inline="true"
               :size="'small'"
               :model="formInline"
               class="demo-form-inline"
               ref="form">
        <el-row :gutter="20">
          <el-col :span="8">
            <el-form-item label="经纪人：">
              <brokerSelect @clearBrokerSelect="clearBrokerSelect"
                            @selectTreeChange="selectTreeChange"
                            :placeholder="'请选择经纪人'"></brokerSelect>
            </el-form-item>
          </el-col>
          <el-col :span="14">
            <el-form-item label="筛选时间："
                          prop="broadcast_date">
              <el-date-picker v-model="formInline.broadcast_date"
                              type="daterange"
                              :clearable="false"
                              range-separator="~"
                              start-placeholder="开始日期"
                              end-placeholder="结束日期"
                              value-format="yyyy-MM-dd">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-button type="primary"
                     @click="searchClick"
                     icon="el-icon-search">搜索</el-button>
        </el-row>
      </el-form>
      <el-tabs style="margin-top:44px"
               v-model="activeName"
               @tab-click="handleClick">
        <el-tab-pane label="总计"
                     name="总计"></el-tab-pane>
        <el-tab-pane label="开播主播"
                     name="开播主播"></el-tab-pane>
        <el-tab-pane label="有效开播主播"
                     name="有效开播主播"></el-tab-pane>
      </el-tabs>
      <diagram ref="diagram"
               :chartHeight="374"
               :chartName="activeName"
               :xAxisData="xAxisData"
               :grid="grid"
               :interval="1"
               :seriesData="seriesData"></diagram>
      <tablePage :tableData="tableData"
                 style="margin-top:30px"
                 @sort-change="tableSortChange"
                 :currentPage="formInline.page"
                 :height="500"
                 @current-change="currentChange"
                 v-loading="loading"
                 ref="tableList"
                 :total="total"
                 id="table">
        <el-table-column label="日期"
                         prop="broadcast_date"
                         :sortable="'custom'"
                         min-width="300">
        </el-table-column>
        <el-table-column label="直播礼物收入"
                         prop="broadcast"
                         :sortable="'custom'"
                         min-width="300">
        </el-table-column>
        <el-table-column label="开播主播"
                         prop="active_anchor"
                         :sortable="'custom'"
                         min-width="300">
        </el-table-column>
        <el-table-column label="有效开播主播"
                         prop="effective_active_anchor"
                         :sortable="'custom'"
                         min-width="300">
        </el-table-column>
        <el-table-column label="新增主播"
                         prop="new_anchors"
                         :sortable="'custom'"
                         min-width="300">
        </el-table-column>
      </tablePage>
    </el-card>
  </div>
</template>

<script>
import brokerSelect from 'components/brokerSelect'
import diagram from 'components/diagram'
import api from '@/api/index'
import tablePage from 'components/tablePage'
import authRouter from 'mixins/authRouter'
export default {
  components: { brokerSelect, diagram, tablePage },
  mixins: [authRouter],
  data () {
    return {
      formInline: {
        broadcast_date: [],
        broker: null,
        broker_id: '',
        page: 1,
        order: ''
      },
      activeName: '总计',
      xAxisData: [],
      seriesData: [],
      grid: {
        top: 30,
        left: 30,
        right: 30,
        bottom: 20,
      },
      tableData: [],
      loading: true,
      total: 10
    }
  },
  created () {
    this.dateFn()
    this._initState()
  },
  methods: {
    tableSortChange ({ prop, order }) {
      if (order) {
        this.formInline.order = `${prop},${order === 'descending' ? order.substr(0, 4) : order.substr(0, 3)}`
        this._initState(this.formInline, true)
      }
    },
    dateFn () {
      this.formInline.broadcast_date.push(this.$timeFormat(
        new Date().setTime(
          new Date(new Date().getFullYear(), new Date().getMonth(), 1)
        ),
        "yyyy-mm-dd"
      ))
      this.formInline.broadcast_date.push(this.$timeFormat(new Date().getTime(), "yyyy-mm-dd"))
    },
    setChartData (type) {
      this.activeName = type.lines[0]
      this.xAxisData = type.dates
      this.seriesData = type.data && type.data[0]
    },
    async _initState (params = { ...this.formInline }, isOrder = false) {
      if (!isOrder) {
        const { status_code, message: { active_anchor_charts, total, effective_anchor_charts } } = await api.businessChartsApi(params)
        if (status_code === 200) {
          if (this.activeName === '开播主播') {
            this.setChartData(active_anchor_charts)
            this.grid.left = 30
          } else if (this.activeName === '总计') {
            this.setChartData(total)
            this.grid.left = 70
          } else if (this.activeName === '有效开播主播') {
            this.setChartData(effective_anchor_charts)
            this.grid.left = 30
          }
        }
      }
      const data = await api.businessListApi(params)
      if (data.status_code === 200) {
        this.tableData = data.message
        this.loading = false
        this.total = data.meta.pagination.total
        this.$nextTick(() => {
          this.$refs.diagram && this.$refs.diagram.initChart()
        })
      } else if (data.status_code === 422) {
        this.$message({
          message: data.message.broadcast_date,
          type: 'error'
        });
      }
    },
    exportClick () {
      this.$excelDownload(this.formInline, "api/business/export");
    },
    selectTreeChange (id) {
      this.formInline.broker_id = id
    },
    clearBrokerSelect () {
      this.formInline.broker_id = ''
    },
    searchClick () {
      this.formInline.page = 1
      this._initState()
    },
    handleClick () {
      this._initState()
    },
    currentChange () { },
  }
}
</script>

<style lang="less" scoped>
.header {
  display: flex;
  justify-content: space-between;
}
h6 {
  font-size: 20px;
  color: #333333;
  font-weight: 500;
}
.btn {
  line-height: 20px;
  font-size: 16px;
  color: #d74d38;
  cursor: pointer;
  span {
    display: inline-block;
    margin-left: 4px;
  }
}
.operation /deep/ .el-tabs__nav-wrap::after {
  background-color: #eeeeee;
  height: 1px;
}
</style>