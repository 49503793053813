<template>
  <el-select v-model="inviter_name"
             :placeholder="placeholder"
             :clearable="clearable"
             @clear="clearSelect"
             @visible-change="selectChange"
             ref="brokerSelect">
    <div class="input-wrap">
      <el-input placeholder="输入关键字进行过滤"
                v-model="filterText">
      </el-input>
    </div>
    <el-option :value="inviter_id"
               :label="inviter_name"
               style="width: auto;height:200px;overflow: auto;background-color:#fff">
      <el-tree :data="treeData"
               ref="tree"
               node-key="id"
               :default-expanded-keys="defaultExpanded"
               v-loading="loading"
               :highlight-current="true"
               :expand-on-click-node="false"
               :check-on-click-node="true"
               :filter-node-method="filterNode"
               :props="defaultProps"
               @node-click="handleNodeClick"></el-tree>
    </el-option>
  </el-select>
</template>

<script>
import api from '@/api/index'
export default {
  props: {
    inviterObj: { //编辑时候回显用的
      type: Object,
      default: () => { }
    },
    placeholder: {
      type: String,
      default: '请选择'
    },
    clearable: {
      type: Boolean,
      default: true
    },
    defaultExpanded: {
      type: Array,
      default: () => ['mine', 'broker', 2, 3, 5, 14, 40, 64, 415, 511, 565, 584, 889, 1220]
    }
  },
  data () {
    return {
      filterText: '',
      loading: true,
      treeData: [],
      inviter_name: '',
      inviter_id: null,
      defaultProps: {
        children: 'distributors_tree_formed',
        label: 'title'
      }
    }
  },
  created () {
    this.isEdit()
    this._initState()
  },
  watch: {
    filterText (val) {
      this.$refs.tree.filter(val);
    }
  },
  methods: {
    delScrollBar () {
      this.$nextTick(() => {
        const dom = document.getElementsByClassName('el-scrollbar__thumb')
        dom[dom.length - 1].style.display = 'none'
      })
    },
    clearSelect () {
      this.$emit('clearBrokerSelect')
    },
    selectChange (val) {
      if (!val) {
        this.filterText ? this.filterText = '' : ''
        this.$emit('selectTreeChange', this.inviter_id)
      }
      this.delScrollBar()
    },
    filterNode (value, data) {
      if (!value) return true;
      return data.title.indexOf(value) !== -1;
    },
    isEdit () {
      if (this.inviterObj && this.inviterObj.id) {
        this.inviter_id = this.inviterObj.id
        this.inviter_name = this.inviterObj.name
      }
    },
    async _initState () {
      const { status_code, message } = await api.brokerTreeListApi()
      if (status_code === 200) {
        this.treeData = message
      }
      this.loading = false
    },
    handleNodeClick (data) {
      if (data.title === '我的' || data.title === '经纪人') {
        this.$message({
          type: 'warning',
          message: '请重新选择'
        })
        return
      }
      this.$refs.brokerSelect.blur()
      this.inviter_name = data.title
      this.inviter_id = data.id
    }
  }
}
</script>

<style lang="less" scoped>
.input-wrap {
  width: 100%;
  padding: 10px 20px;
}
</style>